/* eslint-disable no-case-declarations */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { Dayjs, PluginFunc } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { isDefined } from '~/utils/extra';

type TKey = 'm' | 'mm' | 'hh' | 'dd' | 'MM' | 'yy';

interface IThresholds {
  hh?: number
}

interface IDateFormatterOptions {
  thresholds?: IThresholds
}

function plural(word: string, num: number) {
  const forms = word.split('_');
  return num % 10 === 1 && num % 100 !== 11 ? forms[0] : (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20) ? forms[1] : forms[2]) // eslint-disable-line
}

const OskellyDaysPlugin: PluginFunc = (options, dayjsClass, dayjsFactory) => {
  const locale = dayjsFactory.locale();
  const localeParams = dayjsFactory.Ls[locale];

  dayjsClass.prototype.formatDate = function (this: Dayjs, format = 'DD.MM.YYYY') {
    const result = this.format(format);

    switch (locale) {
      case 'ru':
        return format.includes('MMM') ? result.replace('.', '') : result;
      default:
        return result;
    }
  };

  dayjsClass.prototype.getTimeFromNow = function (this: Dayjs, short = true) {
    switch (locale) {
      case 'ru':
        const h = short ? 'ч_ч_ч' : 'час_часа_часов';
        const d = short ? 'д_д_д' : 'день_дня_дней';

        dayjsFactory.updateLocale('ru', {
          relativeTime: {
            ...localeParams.relativeTime,
            s: 'минуту',
            hh: (number: number, withoutSuffix: boolean, key: TKey) => `${number} ${plural(h, +number)}`,
            dd: (number: number, withoutSuffix: boolean, key: TKey) => `${number} ${plural(d, +number)}`,
          },
        });
        break;
      default:
        break;
    }

    return this.fromNow();
  };

  dayjsClass.prototype.getFromMonthYear = function (this: Dayjs) {
    switch (locale) {
      case 'ru':
        // https://github.com/iamkun/dayjs/blob/dev/src/locale/ru.js#L37
        const months: string[] | undefined = localeParams.months?.f;
        const month = months?.[this.month()] ?? this.format('MMMM');

        return `${month} ${this.year()}`;
      default:
        return `${this.format('MMMM YYYY')}`;
    }
  };

  dayjsClass.prototype.getUserOnOskellyTime = function (this: Dayjs) {
    switch (locale) {
      case 'ru':
        dayjsFactory.updateLocale('ru', {
          relativeTime: {
            ...localeParams.relativeTime,
            past: '%s',
            future: '%s',
            s: 'минуту',
            mm: (number: number, withoutSuffix: boolean, key: TKey) => `${number} ${plural('мин_мин_мин', +number)}`,
            MM: (number: number, withoutSuffix: boolean, key: TKey) => `${number} ${plural('мес_мес_мес', +number)}`,
          },
        });
        break;
      default:
        break;
    }

    return this.fromNow();
  };
};

export function useDateFormatter(options?: IDateFormatterOptions) {
  const thresholds = options?.thresholds;

  // https://github.com/fumeapp/dayjs
  const dayjs = useDayjs();
  const { locale } = useI18n();

  if (thresholds) {
    updateRelativeTime();
  }

  const localeComp = computed(() => (locale.value.includes('en') ? 'en' : locale.value));

  dayjs.locale(localeComp.value);
  dayjs.extend(OskellyDaysPlugin);

  function updateRelativeTime() {
    // https://github.com/iamkun/dayjs/blob/8d5d521602a2ee80a5ae13cb916b8d0e6bb287a6/src/plugin/relativeTime/index.js#L24
    const relativeOptions = {
      thresholds: [
        { l: 's', r: 44, d: 'second' },
        { l: 'm', r: 89 },
        { l: 'mm', r: 44, d: 'minute' },
        { l: 'h', r: 89 },
        { l: 'hh', r: thresholds?.hh ?? 21, d: 'hour' },
        { l: 'd', r: 35 },
        { l: 'dd', r: 25, d: 'day' },
        { l: 'M', r: 45 },
        { l: 'MM', r: 10, d: 'month' },
        { l: 'y', r: 17 },
        { l: 'yy', d: 'year' },
      ],
    };

    if (!relativeOptions) return;

    // https://github.com/iamkun/dayjs/blob/8d5d521602a2ee80a5ae13cb916b8d0e6bb287a6/test/plugin/relativeTime.test.js#L121
    delete relativeTime.$i;
    dayjs.extend(relativeTime, relativeOptions);
  }

  function formatDate(date: NotDefined<string | number | Date>, format = 'DD.MM.YYYY') {
    return dayjs(date).formatDate(format);
  }

  function getTimeFromNow(date: Date, short = true) {
    return dayjs(date).getTimeFromNow(short);
  }

  function getFromMonthYear(date: string | number | Date) {
    return dayjs(date).getFromMonthYear();
  }

  function getUserOnOskellyTime(date: Date) {
    return dayjs(date).getUserOnOskellyTime();
  }

  function toUnix(date: NotDefined<string>, format = 'DD.MM.YYYY') {
    return isDefined(date) ? dayjs(date, format, true).unix() : undefined;
  }

  function fromUnix(date: NotDefined<number>, format = 'DD.MM.YYYY') {
    return isDefined(date) ? dayjs.unix(date).format(format) : undefined;
  }

  return {
    formatDate,
    getTimeFromNow,
    getUserOnOskellyTime,
    toUnix,
    fromUnix,
    getFromMonthYear,
  };
}
